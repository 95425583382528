<template>
  <index-content :breadcrumb-items="breadcrumbItems">
    <template #options>
      <div class="n-button-box">
        <n-button ghost type="info" text="daylightSavings" custom-icon="fa fa-info-circle" @on-click="openDls" />
      </div>
    </template>
    <Row class="mt-2 mb-2">
      <n-field-enum-x
        v-model="dlsType"
        enum-name="DaylightSavingType"
        label="daylightSavingType"
        :label-lg="{ span: 3 }"
        @on-change="load"
      />
    </Row>
    <div class="table-responsive">
      <table class="table table-striped table-bordered table-hover text-center n-table">
        <colgroup>
          <col class="type" />
          <col class="startTime" />
          <col class="endTime" />
          <col class="login" />
          <col class="trading" />
          <col class="editing" />
          <col class="transfer" />
          <col v-table-action class="action-edit" />
        </colgroup>
        <thead>
          <tr>
            <th rowspan="2">Session Type</th>
            <th rowspan="2">Start Time</th>
            <th rowspan="2">End Time</th>
            <th colspan="4">Restriction</th>
            <th rowspan="2">Action</th>
          </tr>
          <tr>
            <th>Login</th>
            <th>Trading</th>
            <th>Editing</th>
            <th>Transfer</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="row in records" :key="row.id">
            <td v-enums:MarketSessionType="row.type"></td>
            <td>{{ $t(`enums.Dow.${row.dow1}`) }} {{ row.time1 | datetime('HH:mm:ss') }}</td>
            <td>{{ $t(`enums.Dow.${row.dow2}`) }} {{ row.time2 | datetime('HH:mm:ss') }}</td>
            <td v-enums:LoginRestriction="row.loginRestriction"></td>
            <td v-enums:TradingRestriction="row.tradingRestriction"></td>
            <td v-enums:EditingRestriction="row.editingRestriction"></td>
            <td v-enums:TransferRestriction="row.transferRestriction"></td>
            <td class="n-button-box"><nb-modal @on-click="onBtnShowModal(row)"></nb-modal></td>
          </tr>
        </tbody>
      </table>
    </div>
  </index-content>
</template>

<script>
import Vue from 'vue';
import BaseMarketView from './view';
import ModalMarketSession from './ModalMarketSession';
import ModalDaylightSaving from './ModalDaylightSaving';
import { edit, dls, fetch, validate } from '@/api/market/market-market-session';

export default BaseMarketView.extend({
  name: 'MarketMarketSession',

  data() {
    return {
      dlsType: null,
      preload: true,
    };
  },

  mounted() {
    this.breadcrumbItems = [this.$t(`label.sessions`)];
  },

  methods: {
    doLoad() {
      return fetch(this.dlsType);
    },

    openDls() {
      dls().then(r => {
        this.createModal(ModalDaylightSaving, { props: { records: r, escapable: true }, on: this });
      });
    },

    parse(data) {
      this.dlsType = data.type;
      let datetime = Vue.filter('datetime');
      let startTime = item => ({
        startTime: this.$t(`enums.Dow.${item.dow1}`) + ' ' + datetime(item.time1, 'HH:mm:ss'),
      });

      let endTime = item => ({
        endTime: this.$t(`enums.Dow.${item.dow2}`) + ' ' + datetime(item.time2, 'HH:mm:ss'),
      });

      this.records = data.sessions.map(item => Object.assign({}, item, startTime(item), endTime(item)));
    },

    onBtnShowModal(row) {
      this.createModal(ModalMarketSession, { model: row, on: this });
    },
  },
});
</script>
