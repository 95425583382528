<template>
  <n-modal v-bind="$props" :detail="true" :custom-title="$t('label.daylightSavings')">
    <div class="modal-daylight-saving">
      <table class="table table-bordered table-hover table-striped text-center n-table">
        <thead>
          <th class="from">{{ $t('label.from') }}</th>
          <th class="from">{{ $t('label.to') }}</th>
          <th class="from">{{ $t('label.type') }}</th>
        </thead>
        <tbody>
          <tr v-for="row in records" :key="row.id">
            <td>{{ row.time1 | datetime }}</td>
            <td>{{ row.time2 | datetime }}</td>
            <td v-enums:DaylightSavingType="row.type"></td>
          </tr>
        </tbody>
      </table>
    </div>
  </n-modal>
</template>
<script>
import NModal from '@/components/common/NModal';

export default NModal.extend({
  name: 'ModalMarketHoliday',
  components: { NModal },

  props: {
    records: Array,
  },
});
</script>

<style lang="scss" scoped>
.modal-daylight-saving {
  max-height: 500px;
  overflow-y: scroll;
}
</style>
