<template>
  <n-modal v-bind="$props" sub-name="session">
    <Form ref="modalForm" :model="model" :label-width="labelWidth" class="n-modal-container" @submit.native.prevent>
      <n-row-col2>
        <!-- time1 -->
        <!-- prettier-ignore -->
        <n-field-text v-model="model.startTime" field-name="time1" label="startTime" :error="error.time1" :disabled="isEdit || disabled" />
        <!-- time2 -->
        <!-- prettier-ignore -->
        <n-field-text v-model="model.endTime" field-name="time2" label="endTime" :error="error.time2" :disabled="isEdit || disabled" />
      </n-row-col2>
      <n-row-col2>
        <!-- type -->
        <!-- prettier-ignore -->
        <n-field-enum v-model="model.type" field-name="type" enum-name="MarketSessionType" label="sessionType" :error="error.type" :disabled="isEdit || disabled" />
        <!-- daylightSavingType -->
        <!-- prettier-ignore -->
        <n-field-enum v-model="model.daylightSavingType" field-name="DaylightSavingType" enum-name="DaylightSavingType" label="daylightSaving" :error="error.daylightSavingType" :disabled="isEdit || disabled" />
      </n-row-col2>
      <n-row-col2>
        <!-- loginRestriction -->
        <!-- prettier-ignore -->
        <n-field-enum v-model="model.loginRestriction" field-name="loginRestriction" enum-name="LoginRestriction" label="loginRestriction" :error="error.loginRestriction" :disabled="disabled" />
        <!-- tradingRestriction -->
        <!-- prettier-ignore -->
        <n-field-enum v-model="model.tradingRestriction" field-name="tradingRestriction" enum-name="TradingRestriction" label="tradingRestriction" :error="error.tradingRestriction" :disabled="disabled" />
      </n-row-col2>
      <n-row-col2>
        <!-- editingRestriction -->
        <!-- prettier-ignore -->
        <n-field-enum v-model="model.editingRestriction" field-name="editingRestriction" enum-name="EditingRestriction" label="editingRestriction" :error="error.editingRestriction" :disabled="disabled" />
        <!-- transferRestriction -->
        <!-- prettier-ignore -->
        <n-field-enum v-model="model.transferRestriction" field-name="transferRestriction" enum-name="TransferRestriction" label="transferRestriction" :error="error.transferRestriction" :disabled="disabled" />
      </n-row-col2>
    </Form>
  </n-modal>
</template>

<script>
import NModal from '@/components/common/NModal';
import { validate, edit } from '@/api/market/market-market-session';

export default NModal.extend({
  name: 'ModalMarketSession',
  components: { NModal },

  methods: {
    doSubmit(model) {
      return edit(model);
    },

    doValidate(model) {
      return validate(model);
    },
  },
});
</script>
