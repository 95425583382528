import { $fetch, $post } from '@/services/axios';

export function fetch(type) {
  let params = {};
  if (type) params.type = type;
  return $fetch(`market/sessions`, params);
}

export function validate(data) {
  return $post(`market/session/validate`, data);
}

export function edit(data) {
  return $post(`market/session/${data.id}`, data);
}

export function dls() {
  return $fetch(`market/session/daylight-savings`);
}
